/* styles.css or use styled-components */
.header_copy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 31px 10px;
    position: sticky;
    top: 0;
    z-index: 100;
    backdrop-filter: blur(10px);
}

.nav_copy {
    border-radius: 38px;
    background: linear-gradient(0deg, rgba(45, 45, 45, 0.20) 0%, rgba(45, 45, 45, 0.20) 100%), #1B1A1A;
}

.logo img {
    width: 200px;
    /* max-width: 200px; */
    /* margin: 5px 5px 5px 0; */
    cursor: pointer;
}

.nav ul {
    list-style: none;
    display: flex;
    gap: 0px;
    margin: 0 !important;
}

.nav li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 17px;
    font-style: normal;
    cursor: "pointer";
    font-weight: 700;
}

.buy-now {
    padding: 10px 29px;
    width: 100%;
    max-width: 147.613px;
    /* height: 56px; */
    border-radius: 38px;
    background: #F6F6F6;
    border: none;
    color: #08001F;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.hamburger-css>svg {
    font-size: 47px;
    color: #F6F6F6;

}

.menu-box {
    padding: 5px 8px;
    border-radius: 7px;
    border: 1px solid #BFBFBF;
    background: rgba(14, 14, 14, 0.65);
    backdrop-filter: blur(22px);
    position: absolute;
    width: 100%;
    max-width: 200px;
    top: 101px;
    right: auto;
    left: 228px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.select-buttons {
    color: #F6F6F6;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    border-radius: 5px;
    border: 0.5px solid #474747;
    background: #0E0E0E;
    padding: 6px;
}

.menu>.MuiMenu-paper {
    border-radius: 7px !important;
    border: 1px solid #BFBFBF !important;
    background: rgba(14, 14, 14, 0.65) !important;
    backdrop-filter: blur(22px) !important;
}

.menu>.MuiMenu-paper {
    color: #F6F6F6 !important;
}

.signup {
    /* font-size: small; */
}
.signin {
    /* font-size: small; */
    background: linear-gradient(0deg, rgba(45, 45, 45, 0.20) 0%, rgba(45, 45, 45, 0.20) 100%), #1B1A1A;
    color: white;
    /* padding: 0;

    padding-right: 0 !important; */
}

.buy-button {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 480px) {
    .logo img {
        width: 100%;
        /* max-width: 140px; */
        max-width: 129px;
        margin: 10px 5px 5px 0;
    }
}

@media (max-width: 1300px) {
    .nav li {
        font-size: 15px;
    }
}

@media (max-width: 899px) {
    .header {
        padding: 5px 10px;
    }
}

@media (min-width: 1000px) {
    .hamburger-css {
        display: none;

    }
}

@media (max-width: 1000px) {
    .nav {
        display: none;
        /* Hide navigation links on mobile */
    }

    .buy-button {
        display: flex;
        align-items: center;
        gap: 10px;
    }

}

@media (max-width: 500px) {
    .buy-now {
        /* padding: 13px 17px; */
        padding: 9px 9px;
        border-radius: 15px;
        font-size: 11px;

    }

    .hamburger-css>svg {
        /* font-size: 47px; */
        font-size: 26px;

    }


}

@media (min-width: 899px) and (max-width: 1200px) {
    .header {
        padding: 5px 27px;
    }
}

@media screen and (max-width: 1000px) {
    /* .header_copy{
        background-color: rgba(0, 0, 0, 0.5);
    } */
}