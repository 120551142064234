@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
body {
  background-color: transparent !important;
}
button{border:none;}
.App {
  text-align: center;
}
button:focus-visible {
  outline: none !important;
}
p {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
}

label {
  font-family: "IBM Plex Sans", sans-serif;
}

button {
  font-family: "IBM Plex Sans", sans-serif;
}

h1 {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  font-size: 70px;
}

h2 {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  font-size: 45px;
}

h3 {
  margin: 0;
  font-family:  "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 141.023%;
}
h4{
    margin: 0;
    font-family: "IBM Plex Sans", sans-serif;
}
@media screen and (max-width: 600px) {
  h1 {
    margin: 0;
    font-family: 'Outfit', sans-serif;
    font-size: xx-large;
  }
  
  h2 {
    margin: 0;
    font-family: 'Outfit', sans-serif;
    font-size: x-large;
  }
  .header_copy{
    padding: 10px !important;
  }
  .header{
    padding: 10px !important;
  }
}
li {
  font-family: 'Inter', sans-serif;
}

.MuiDialogActions-root {
  flex: 0 0 auto;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center !important;
}

.moveTop {
  -webkit-animation: moveTop 5s normal linear infinite;
  animation: moveTop 5s normal linear infinite;
}

@-webkit-keyframes moveTop {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes moveTop {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.moveLeft {
  -webkit-animation: moveLeft 5s normal linear infinite;
  animation: moveLeft 5s normal linear infinite;
}

@-webkit-keyframes moveLeft {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0px);
  }
}

::-webkit-scrollbar {
  display: none;
}

.slick-track {
  display: flex !important;
  gap: 20px
}


/* start css for background stars animation */

html {
  height: 100%;
  background: black;
  overflow: auto;
}





/* end css for background stars animation */
/* pie chart css */

.doughnut-chart-container {
  position: relative;
  text-align: center;
  /* Center the text inside the container */
}

.doughnut-chart-container:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  /* Adjust the width to control the size of the hole in the doughnut */
  height: 120px;
  /* Adjust the height to control the size of the hole in the doughnut */
  background-color: #fff;
  /* Set the background color to match the chart background */
  border-radius: 50%;
  z-index: 1;
  /* Ensure the hole is above the chart */
}

.doughnut-chart-container .recharts-responsive-container {
  display: inline-block;
}

/* Add some styling to make the tooltip and legend more visually appealing */
.doughnut-chart-container .recharts-tooltip {
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.recharts-default-legend {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.doughnut-chart-container .recharts-legend-item {
  display: inline-block;
  margin-right: 20px;
}

.doughnut-chart-container .recharts-legend-item-text {
  font-size: 14px;
  margin-left: 5px;
}